import React from 'react';
import './MarketplaceOTCSection.css';
import p2pImage from '../assets/p2p.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const MarketplaceOTCSection = () => {
  return (
    <div className="marketplace-otc-section">
      <h2>FIRST PRODUCT - NOT ONLY A MEME</h2>
      <div className="content">
        <div className="text-content">
          <ul>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              The Marketplace OTC allows for P2P trading between individuals looking to buy or sell SORA assets without the impact of slippage or the low liquidity found on Polkaswap.
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              Step-by-step process: Find an order in the Marketplace, both parties connect with the admin via Telegram and then send the cryptocurrencies to the Escrow (Admin's Wallet). Once both deposits are made, the Admin exchanges the assets between wallets, sending them inversely.
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              This is the first product we are launching to demonstrate that we are not just a meme. We will continue creating and adding value to the ecosystem, showing that it is possible to build an ecosystem without a money printer.
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              Commission for the service is 1% on each side of the P2P exchange.
            </li>
          </ul>
          <div className="button-group">
            <a href="https://t.me/+geU9nhbK37VmZDZh" target="_blank" rel="noreferrer" className="telegram-button">
              <FontAwesomeIcon icon={faTelegram} />
              Join us on Telegram
            </a>
            <Link to="/marketplace-otc" className="marketplace-button">
              Go to Marketplace
            </Link>
          </div>
        </div>
        <img src={p2pImage} alt="P2P Trading Illustration" className="p2p-image" />
      </div>
    </div>
  );
};

export default MarketplaceOTCSection;











