import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import MarketplaceOTC from './components/MarketplaceOTC';
import Footer from './components/Footer'; // Importa el componente Footer

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/marketplace-otc" element={<MarketplaceOTC />} />
      </Routes>
      <Footer /> {/* Incluye el componente Footer */}
    </Router>
  );
};

export default App;






