import React from 'react';
import './Roadmap.css';

const Roadmap = () => {
  return (
    <section id="roadmap" className="roadmap">
      <h2>Roadmap</h2>
      <div className="roadmap-cards">
        <div className="card">
          <h3>Q2 2024</h3>
          <p>Study the needs of the SORA ecosystem and gather insights. Launch initial community engagement campaigns.</p>
        </div>
        <div className="card">
          <h3>Q3 2024</h3>
          <p>Conduct the IDO to raise funds supporting the project. Begin partnerships with key players in the crypto space.</p>
        </div>
        <div className="card">
          <h3>Q4 2024</h3>
          <p>Develop and launch the first solution leveraging the raised funds. Establish a robust development and marketing team.</p>
        </div>
        <div className="card">
          <h3>Q1 2025</h3>
          <p>Initiate token buybacks and new investments. Expand the ecosystem with strategic acquisitions.</p>
        </div>
        <div className="card">
          <h3>Q2 2025</h3>
          <p>Launch additional products and services to enhance the SORA ecosystem. Implement governance and staking mechanisms.</p>
        </div>
        <div className="card">
          <h3>Q3 2025</h3>
          <p>Grow community outreach programs and increase global awareness. Aim for $1B in MarketCap with sustained growth initiatives.</p>
        </div>
        <div className="card">
          <h3>Q4 2025</h3>
          <p>Evaluate progress and adapt strategies based on market feedback. Continue scaling and improving ecosystem offerings.</p>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;
