import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoPrinter from '../assets/logo_printer.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';
import './Header.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <img src={logoPrinter} alt="WithoutPrinter Logo" className="logo" />
      <nav className={`nav ${isOpen ? 'active' : ''}`}>
        <Link to="/">Home</Link>
        <a href="#tokenomics">Tokenomics</a>
        <a href="#roadmap">Roadmap</a>
        <Link to="/marketplace-otc">Marketplace OTC</Link>
      </nav>
      <div className="cta-container">
        <a href="https://x.com/WithoutP91319" target="_blank" className="cta" rel="noreferrer">
          <FontAwesomeIcon icon={faXTwitter} />
        </a>
        <a href="https://t.me/+geU9nhbK37VmZDZh" target="_blank" className="cta" rel="noreferrer">
          <FontAwesomeIcon icon={faTelegram} />
        </a>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </header>
  );
}

export default Header;










