import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = () => {
  const data = {
    labels: [
      'Public Sale: 60% (210,000 PRINTER)',
      'Exchange Liquidity: 20% (70,000 PRINTER)',
      'Team and Developers: 10% (35,000 PRINTER)',
      'Ecosystem and Development Fund: 5% (17,500 PRINTER)',
      'Marketing and Partnerships: 5% (17,500 PRINTER)'
    ],
    datasets: [
      {
        data: [60, 20, 10, 5, 5],
        backgroundColor: [
          'hsla(11, 81%, 74%, 0.68)',
          '#ff3b7b',
          'rgba(24, 165, 184, 0.2)',
          'rgba(171, 24, 184, 0.25)',
          'rgba(242, 44, 109, 0.38)'
        ],
        hoverBackgroundColor: [
          'hsla(11, 81%, 74%, 0.68)',
          '#ff3b7b',
          'rgba(24, 165, 184, 0.2)',
          'rgba(171, 24, 184, 0.25)',
          'rgba(242, 44, 109, 0.38)'
        ]
      }
    ]
  };

  return (
    <div className="chart-container">
      <Pie data={data} />
    </div>
  );
}

export default PieChart;