import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAwR137nfHot-bwY-dZ4Ce0oOkiPlimgFY",
    authDomain: "without-printer.firebaseapp.com",
    projectId: "without-printer",
    storageBucket: "without-printer.appspot.com",
    messagingSenderId: "767752893473",
    appId: "1:767752893473:web:af3eb72150470634fe1f60",
    measurementId: "G-T7NF0H3NVP"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
