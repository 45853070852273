import React from 'react';
import PieChart from './PieChart';
import './Tokenomics.css';

const Tokenomics = () => {
  return (
    <section id="tokenomics" className="tokenomics">
      <h2>Tokenomics</h2>
      <div className="tokenomics-content">
        <PieChart />
        <p className="token-description">
          The token allocation is designed to ensure the growth and sustainability of the project. 
          Public Sale will fuel initial adoption, Exchange Liquidity will facilitate trading, 
          Team and Developers allocation ensures continuous development, 
          Ecosystem and Development Fund supports project expansion, 
          and Marketing and Partnerships drive awareness and collaboration.
        </p>
      </div>
    </section>
  );
}

export default Tokenomics;