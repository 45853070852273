import React from 'react';
import './Hero.css';
import heroImage from '../assets/logo_printer.png';

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-text">
        <h1>Makoto without $PRINTER</h1>
        <h1>SORA without $PRINTER</h1>
        <h1>XOR without $PRINTER</h1>
        <p>
          What would have XOR been without Makoto's printer with a max supply of 350,000 tokens?
          $PRINTER aims to demonstrate that printing is stealing. We will always protect investors.
          With the IDO, we will develop or acquire projects to generate value for the token and the SORA ecosystem.
        </p>
      </div>
      <div className="hero-image-container">
        <img src={heroImage} alt="Hero" className="hero-image" />
        <div className="cta-container">
          <a href="https://x.com/WithoutP91319" target="_blank" className="cta" rel="noreferrer">Join the Community</a>
        </div>
      </div>
    </div>
  );
}

export default Hero;

