import React from 'react';
import mileiImage from '../assets/milei.png'; // Asegúrate de que la ruta sea correcta
import './MileiBanner.css';

const MileiBanner = () => {
  return (
    <div className="milei-banner">
      <img src={mileiImage} alt="Javier Milei" className="milei-image" />
      <div className="milei-text">
        <p className="milei-quote">"Inflation is always and everywhere a monetary phenomenon"</p>
        <p className="milei-author">- Javier Milei, President of Argentina</p>
      </div>
    </div>
  );
}

export default MileiBanner;