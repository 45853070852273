import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from '../firebaseConfig';
import './MarketplaceOTC.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';

const MarketplaceOTC = () => {
  const [orders, setOrders] = useState([]);
  const [newOrder, setNewOrder] = useState({
    type: 'Buy',
    token: '',
    quantity: '',
    price: '',
    telegram: '',
    status: 'Pending'
  });
  const [password, setPassword] = useState('');
  const [showForm, setShowForm] = useState(false);
  const adminPassword = 'your_admin_password';

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "orders"));
      const ordersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setOrders(ordersData);
    } catch (error) {
      console.error("Error fetching documents: ", error);
    }
  };

  const handleChange = (e) => {
    setNewOrder({ ...newOrder, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "orders"), newOrder);
      fetchOrders();
      setNewOrder({ type: 'Buy', token: '', quantity: '', price: '', telegram: '', status: 'Pending' });
      setShowForm(false);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleStatusChange = async (id, newStatus) => {
    if (password !== adminPassword) {
      alert("Incorrect password");
      return;
    }
    try {
      const orderRef = doc(db, "orders", id);
      await updateDoc(orderRef, { status: newStatus });
      fetchOrders();
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <div className="marketplace-otc">
      <div className="rules">
        <ul>
          <li>All deals are conducted via direct messages on Telegram.</li>
          <li>The team will act as Escrow to prevent scams and ensure funds.</li>
          <li>For support, help, or contributions, contact us through Telegram: @WithoutPrinter.</li>
        </ul>
        <a href="https://t.me/+geU9nhbK37VmZDZh" target="_blank" rel="noreferrer" className="x-button">
          <FontAwesomeIcon icon={faTelegram} />
          Contact Us on Telegram
        </a>
      </div>
      <div className="content">
        <h2>Marketplace OTC</h2>
        <button className="new-order-btn" onClick={toggleForm}>
          {showForm ? 'Hide Form' : 'New Order'}
        </button>
        {showForm && (
          <form onSubmit={handleSubmit} className="order-form">
            <label>
              Type:
              <select name="type" value={newOrder.type} onChange={handleChange}>
                <option value="Buy">Buy</option>
                <option value="Sell">Sell</option>
              </select>
            </label>
            <label>
              Token:
              <input type="text" name="token" value={newOrder.token} onChange={handleChange} required />
            </label>
            <label>
              Quantity:
              <input type="number" name="quantity" value={newOrder.quantity} onChange={handleChange} required />
            </label>
            <label>
              Price (USDT):
              <input type="number" name="price" value={newOrder.price} onChange={handleChange} required />
            </label>
            <label>
              Telegram:
              <input type="text" name="telegram" value={newOrder.telegram} onChange={handleChange} required />
            </label>
            <button type="submit">Submit Order</button>
          </form>
        )}
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Token</th>
              <th>Quantity</th>
              <th>Price (USDT)</th>
              <th>Telegram</th>
              <th>Status</th>
              <th>Change Status</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr key={index}>
                <td>{order.type}</td>
                <td>{order.token}</td>
                <td>{order.quantity}</td>
                <td>{order.price}</td>
                <td>{order.telegram}</td>
                <td>{order.status}</td>
                <td>
                  <div className="dropdown">
                    <button className="dropbtn">Change Status</button>
                    <div className="dropdown-content">
                      <input
                        type="password"
                        placeholder="Admin Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button onClick={() => handleStatusChange(order.id, 'Completed')}>Mark as Completed</button>
                      <button onClick={() => handleStatusChange(order.id, 'Cancelled')}>Mark as Cancelled</button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MarketplaceOTC;






