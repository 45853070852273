import React from 'react';
import Hero from './Hero';
import Tokenomics from './Tokenomics';
import Roadmap from './Roadmap';
import MileiBanner from './MileiBanner';
import MarketplaceOTCSection from './MarketplaceOTCSection';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <Hero />
      <MileiBanner />
      <Tokenomics />
      <Roadmap />
      <MarketplaceOTCSection /> {/* Añade la nueva sección aquí */}
    </div>
  );
};

export default Home;



